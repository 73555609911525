/**
 * Checks if the widget is being used in an embedded theme context.
 * An embedded theme is one that serves ReCharge Customer Portal using an external FE application,
 * Affinity is an example of an embedded theme in ReCharge.
 * @returns boolean If the widget is being served in an embedded theme context.
 */
function usedWithinEmbeddedThemeContext() {
  return Boolean(window.ReCharge?.settings?.customer_portal);
}

export { usedWithinEmbeddedThemeContext };
